import { motion } from 'framer-motion';
import parse from 'html-react-parser';

const About = (props) => {

  const { about } = props;

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="page page--about"
    >
      <div className="experience__container">
        {
          about.content &&
          <div className="page__content">
            {parse(about.content)}
          </div>
        }
      </div>
    </motion.div>
  )
}

export default About;