import { useRef, useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { fetchCockpitData } from './utils/cockpit';
import Content from './components/Content';

const App = () => {
  const [windowDimensions, setWindowDimensions] = useState({
    windowHeight: window.innerHeight,
    windowWidth: window.innerWidth
  });
  const { windowWidth, windowHeight } = windowDimensions;
  const [sidebarIsActive, setSidebarIsActive] = useState(false);
  const [userIsTouching, setUserIsTouching] = useState(false);
  const [isResizing, setIsResizing] = useState(false);
  const [about, setAbout] = useState({});
  const [experience, setExperience] = useState({});
  const [research, setResearch] = useState({});
  const [homepage, setHomepage] = useState({});

  const navigate = useNavigate();
  const location = useLocation();
  const { pathname } = location;

  const wrapper = useRef();
  const main = useRef();

  useEffect(() => {
  }, [pathname]);

  useEffect(() => {
    fetchCockpitData('about', setAbout, false);
    fetchCockpitData('experience', setExperience, false);
    fetchCockpitData('research', setResearch, false);
    fetchCockpitData('homepage', setHomepage, false);
  }, []);

  useEffect(() => {
    let resizingTimeout;

    const handleWindowResize = () => {
      clearTimeout(resizingTimeout);
      setIsResizing(true);
      setWindowDimensions({
        windowHeight: window.innerHeight,
        windowWidth: window.innerWidth
      });
      
      // set a timeout to set a resizing state
      // this helps us prevent weird CSS transitions while the window is being resized
      resizingTimeout = setTimeout(() => {
        setIsResizing(false);
      }, 200);
    }

    // get the dimensions of the window on initial load
    handleWindowResize();

    //add an event listener to update the window dimensions on every window resize
    window.addEventListener('resize', handleWindowResize);
    return () => {
      window.removeEventListener('resize', handleWindowResize);
    }
  }, []);

  return (
    <Content
      userIsTouching={userIsTouching}
      main={main}
      wrapper={wrapper}
      windowWidth={windowWidth}
      windowHeight={windowHeight}
      windowDimensions={windowDimensions}
      sidebarIsActive={sidebarIsActive}
      setSidebarIsActive={setSidebarIsActive}
      pathname={pathname}
      setUserIsTouching={setUserIsTouching}
      isResizing={isResizing}
      setIsResizing={setIsResizing}
      location={location}
      navigate={navigate}
      about={about}
      experience={experience}
      research={research}
      homepage={homepage}
    />
  );
}

export default App;