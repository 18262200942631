import { useRef, useEffect, Suspense } from 'react';
import lerp from '../utils/lerp';
import * as THREE from 'three';
import { useFBX, useTexture } from '@react-three/drei';
import head from '../../assets/head2.fbx';
import { useFrame } from '@react-three/fiber';
import hdri from '../../assets/hdri2.jpg';

const HeadFBX = (props) => {

  const headFBX = useFBX(head);

  useEffect(() => {
    if (headFBX) {
      headFBX.traverse((child) => {
        if (child.isMesh) {
          const envMap = new THREE.TextureLoader().load(hdri);
          envMap.anisotropy = 16;
          envMap.mapping = THREE.EquirectangularReflectionMapping;
          
          const map = new THREE.TextureLoader().load(hdri);
          map.repeat.x = 0.06;
          map.repeat.y = 0.06;
          map.anisotropy = 16;

          const material = new THREE.MeshPhysicalMaterial({
            reflectivity: 0.99,
            side: THREE.DoubleSide,
            // color: 0x555555,
            envMap: envMap,
            map: map,
            roughness: 0,
            metalness: 0.99
          });

          child.material = material;
        }
      });
    }
  }, [headFBX]);

  return (
    <primitive object={headFBX} />
  )
}

const HomepageScene = (props) => {

  const { windowWidth } = props;
  const targetRotation = useRef({ x: 0, y: 0 });
  const mesh = useRef();
  const skySphere = useRef();
  const texture = useTexture(hdri);

  useFrame(({ mouse }) => {
    targetRotation.current.y = mouse.x * Math.PI * 0.1;
    targetRotation.current.x = -mouse.y * Math.PI * 0.1;

    if (mesh.current) {
      mesh.current.rotation.y = lerp(mesh.current.rotation.y, targetRotation.current.y, 0.03);
      mesh.current.rotation.x = lerp(mesh.current.rotation.x, targetRotation.current.x, 0.03);
    }

    if (skySphere.current) {
      skySphere.current.rotation.y = lerp(skySphere.current.rotation.y, -targetRotation.current.y, 0.03);
      skySphere.current.rotation.x = lerp(skySphere.current.rotation.x, -targetRotation.current.x, 0.03);
    }
  });

  return (
    <group>
      <Suspense fallback={null}>
        <mesh
          ref={mesh}
          position={[0, -1.2, 0]}
          scale={[0.024, 0.024, 0.024]}
        >
          <HeadFBX {...props} />
        </mesh>
      </Suspense>
      <group rotation={[0, Math.PI * 1.4, 0]}>
        {
          texture.uuid && windowWidth > 499 &&
          <mesh position={[0, 0, 0]} ref={skySphere}>
            <sphereBufferGeometry args={[6, 128]} />
            <meshPhongMaterial
              color={0x777777}
              shininess={99}
              side={THREE.BackSide}
              needsUpdate={true}
            >
              <Suspense fallback={null}>
                <primitive
                  attach="map"
                  object={texture}
                  anisotropy={16}
                  encoding={THREE.sRGBEncoding}
                />
              </Suspense>
            </meshPhongMaterial>
          </mesh>
        }
      </group>
      <pointLight
        intensity={0.4}
        position={[0, 0, 4]}
      />
      <ambientLight intensity={2} />
    </group>
  )
};

export default HomepageScene;