import { motion } from 'framer-motion';
import parse from 'html-react-parser';

const Research = (props) => {

  const { research } = props;

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="page page--research"
    >
      <div className="experience__container">
        {
          research.content &&
          <div className="page__content">
            {parse(research.content)}
          </div>
        }
      </div>
    </motion.div>
  )
}

export default Research;