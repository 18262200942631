import { useRef, useEffect, Suspense } from 'react';
import lerp from '../utils/lerp';
import * as THREE from 'three';
import { useFBX } from '@react-three/drei';
import head from '../../assets/head-header.fbx';
import headLowPoly from '../../assets/head-lowpoly.fbx';
import { useFrame } from '@react-three/fiber';
import hdri from '../../assets/hdri2.jpg';

const HeadFBXLowPoly = () => {

  const headFBXLowPoly = useFBX(headLowPoly);

  useEffect(() => {
    if (headFBXLowPoly) {
      headFBXLowPoly.traverse((child) => {
        if (child.isMesh) {
          const envMap = new THREE.TextureLoader().load(hdri);
          envMap.anisotropy = 16;
          envMap.mapping = THREE.EquirectangularReflectionMapping;
          
          const map = new THREE.TextureLoader().load(hdri);
          map.repeat.x = 0.06;
          map.repeat.y = 0.06;
          map.anisotropy = 16;

          const material = new THREE.MeshStandardMaterial({
            color: 0xaa99ff,
            wireframe: true
          });

          child.material = material;
        }
      });
    }
  }, [headFBXLowPoly]);

  return (
    <primitive object={headFBXLowPoly} />
  );
}

const HeadFBX = () => {

  const headFBX = useFBX(head);

  useEffect(() => {
    if (headFBX) {
      headFBX.traverse((child) => {
        if (child.isMesh) {
          const envMap = new THREE.TextureLoader().load(hdri);
          envMap.anisotropy = 16;
          envMap.mapping = THREE.EquirectangularReflectionMapping;
          
          const map = new THREE.TextureLoader().load(hdri);
          map.repeat.x = 0.06;
          map.repeat.y = 0.06;
          map.anisotropy = 16;

          const material = new THREE.MeshPhysicalMaterial({
            reflectivity: 0.6,
            envMap: envMap,
            map: map,
            emissive: 0xffffff,
            emissiveIntensity: 0.02,
            roughness: 0.3,
            metalness: 0.3
          });

          child.material = material;
        } else {
          console.log(child)
        }
      });
    }
  }, [headFBX]);

  return (
    <primitive object={headFBX} />
  )
}

const HeaderScene = (props) => {

  const targetRotation = useRef({ x: 0, y: 0 });
  const mesh = useRef();

  useFrame(({ mouse }) => {
    targetRotation.current.y = mouse.x * Math.PI * 0.02;
    targetRotation.current.x = -mouse.y * Math.PI * 0.02;

    if (mesh.current) {
      mesh.current.rotation.y = lerp(mesh.current.rotation.y, targetRotation.current.y, 0.03);
      mesh.current.rotation.x = lerp(mesh.current.rotation.x, targetRotation.current.x, 0.03);
    }
  });

  return (
    <group position={[0, 0, 0]}>
      <group
        ref={mesh}
      >
        <Suspense fallback={null}>
          <mesh
            position={[0, -1.6, 0]}
            scale={[0.023, 0.023, 0.023]}
          >
            <HeadFBX {...props} />
          </mesh>
        </Suspense>
        <Suspense fallback={null}>
          <mesh
            position={[0, -1.6, 0]}
            scale={[0.0231, 0.0231, 0.0231]}
          >
            <HeadFBXLowPoly {...props} />
          </mesh>
        </Suspense>
        <pointLight
          intensity={0.4}
          position={[0, 0, 4]}
        />
        <ambientLight intensity={0.1} />
      </group>
    </group>
  )
};

export default HeaderScene;