import { Route, Routes } from 'react-router-dom';
import Header from './Header';
import Homepage from '../views/Homepage';
import Experience from '../views/Experience';
import About from '../views/About';
import Research from '../views/Research';
import { AnimatePresence } from 'framer-motion';

const Content = (props) => {

  const { windowHeight } = props;

  return (
    <div
      className="app"
      style={{
        height: windowHeight + 'px'
      }}
    >
      {
        props.location.pathname !== '/' &&
        <Header {...props} />
      }
      <AnimatePresence exitBeforeEnter={true}>
        <Routes key={props.location.pathname}>
          <Route
            path='/'
            element={<Homepage {...props} />}
          />
          <Route
            path='/experience'
            element={<Experience {...props} />}
          />
          <Route
            path='/about'
            element={<About {...props} />}
          />
          <Route
            path='/research'
            element={<Research {...props} />}
          />
        </Routes>
      </AnimatePresence>
    </div>
  );
};

export default Content;