import { Canvas } from "@react-three/fiber";
import { Link } from "react-router-dom";
import HeaderScene from "./HeaderScene";

const Header = (props) => {

  return (
    <header className="header">
      <Link to="/">
        <h1 style={{ display: 'none' }}>Wanderer</h1>
        <div className="header__canvas__wrapper">
          <Canvas
            onCreated={(state) => state.events.connect(window)}
          >
            <HeaderScene {...props} />
          </Canvas>
        </div>
      </Link>
      <nav className="main-menu">
        <Link
          to="/about"
          className="main-menu__link"
        >About</Link>
        <Link
          to="/research"
          className="main-menu__link"
        >Research</Link>
      </nav>
    </header>
  )
}

export default Header;