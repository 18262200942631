import { Canvas } from '@react-three/fiber';
import { AnimatePresence, motion } from 'framer-motion';
import parse from 'html-react-parser';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import HomepageScene from '../components/HomepageScene';
import Loader from '../utils/Loader';

const Homepage = (props) => {

  const { homepage } = props;
  const { intro } = homepage;
  const [sceneIsLoaded, setSceneIsLoaded] = useState(false);

  return (
    <div className="homepage">
      {
        sceneIsLoaded === true &&
        <Link className="homepage__start-button" to="/experience">
          Enter experience
        </Link>
      }
      <Canvas>
        <Loader setSceneIsLoaded={setSceneIsLoaded} />
        <HomepageScene {...props} setSceneIsLoaded={setSceneIsLoaded} />
      </Canvas>
      {
        sceneIsLoaded === true && intro &&
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className="homepage__info">
          <div className="homepage__info__inner">
            <div className="homepage__info__content">
              {intro ? parse(intro) : ''}
            </div>
          </div>
        </motion.div>
      }
      <AnimatePresence>
        {
          sceneIsLoaded === false &&
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="loader"
          >
            <p>Loading...</p>
          </motion.div>
        }
      </AnimatePresence>
    </div>
  )
};

export default Homepage;