import { motion } from 'framer-motion';
import parse from 'html-react-parser';

const Experience = (props) => {

  const { experience, windowWidth } = props;
  const { experienceEmbed, copy } = experience;

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="page page--experience"
    >
      <div className="experience__container">
        {
          windowWidth >= 500 &&
            <div className="experience__wrapper">
              <div className="experience__inner">
                <iframe
                  allowFullScreen={true}
                  crossOrigin="anonymous"
                  title="wanderer experience"
                  className="experience"
                  frameborder="0"
                  src={experienceEmbed}
                />
                {/* <iframe frameborder="0" src="https://itch.io/embed-upload/6208463?color=333333" allowfullscreen="" width="640" height="380">
                <a href="https://tinapulejkova.itch.io/wanderer">Play Wanderer on itch.io</a></iframe> */}
              </div>
            </div>
        }
        <div>
          {copy ? parse(copy) : ''}
        </div>
      </div>
    </motion.div>
  )
}

export default Experience;